import { DistanceSlider } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import pluralize from "pluralize";

import type { UserFilters } from "../types";

interface DistanceFilterProps {
  value: UserFilters["distance"];
  onChange: (value: UserFilters["distance"]) => void;
}

export function DistanceFilter(props: DistanceFilterProps) {
  const { value, onChange } = props;

  return (
    <Stack spacing={5}>
      <Text semibold variant="body1">
        Distance from Home
      </Text>

      <Stack>
        <DistanceSlider
          aria-label="Shift Filters Distance Slider"
          value={value}
          options={[0, 50, 100, 150]}
          step={10}
          onChange={(_event, value) => {
            onChange(value as number);
          }}
        />
      </Stack>

      <Text semibold variant="h5">
        {value} {pluralize("mile", value)}
      </Text>
    </Stack>
  );
}
