import { isDefined } from "@clipboard-health/util-ts";
import { get } from "@src/appV2/api";
import {
  shiftBlocksApiUrl,
  type ShiftBlocksResponse,
  shiftBlocksResponseSchema,
} from "@src/appV2/ShiftBlocks/api/useFetchPaginatedShiftBlocks";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useWorkerQualifications } from "@src/appV2/Worker/useWorkerQualifications";
import { useQuery, type UseQueryOptions } from "@tanstack/react-query";

interface UseGetMultiLicenseAggregatedBlocksProps {
  maxDistanceMiles?: number;
}

/**
 * Fetches shift blocks for each worker license and combines them into a single response.
 */
export function useGetMultiLicenseAggregatedBlocks(
  props: UseGetMultiLicenseAggregatedBlocksProps,
  options: UseQueryOptions<ShiftBlocksResponse> = {}
) {
  const { maxDistanceMiles } = props;

  const worker = useDefinedWorker();
  const workerQualifications = useWorkerQualifications();

  return useQuery({
    queryKey: [shiftBlocksApiUrl, workerQualifications],
    queryFn: async () => {
      const allResponses = await Promise.all(
        workerQualifications.map(async (qualification) => {
          const response = await get({
            url: shiftBlocksApiUrl,
            queryParams: {
              filter: {
                agentId: worker.userId,
                qualification,
                maxDistanceMiles,
              },
              page: {
                size: 100,
                cursor: undefined,
              },
              sort: "distance,startTime",
            },
            responseSchema: shiftBlocksResponseSchema,
          });

          return response.data;
        })
      );

      return allResponses.reduce<ShiftBlocksResponse>(
        (result, response) => {
          const blocks = response.data;
          const workplace = response.included.find((item) => item.type === "facility");

          if (!isDefined(blocks) || blocks.length === 0 || !isDefined(workplace)) {
            return result;
          }

          return {
            ...result,
            data: [...result.data, ...blocks],
            included: [...result.included, ...response.included],
            links: {
              ...result.links,
              nextCursor: undefined,
            },
          };
        },
        { data: [], included: [], links: { nextCursor: undefined } }
      );
    },
    cacheTime: 5 * 60_000,
    staleTime: 2 * 60_000,
    ...options,
  });
}
