import { Rating } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import pluralize from "pluralize";

interface ReviewsSummaryProps {
  rating: number;
  reviewsCount?: number;
  /**
   * Medium size is used in workplace details modal. It shows a h1 label with a rating and star indicator below
   * Small size is for inline use, it renders a row of rating and reviews count together
   */
  size?: "small" | "medium";
}

// TODO: This was overlooked and should be moved to cbh-core
export function ReviewsSummary(props: ReviewsSummaryProps) {
  const { rating, reviewsCount, size = "medium" } = props;

  return (
    <Stack
      spacing={3}
      alignItems={size === "medium" ? "flex-start" : "center"}
      direction={size === "medium" ? "column" : "row"}
    >
      <Text variant={size === "medium" ? "h1" : "body1"}>{rating.toFixed(1)}</Text>

      <Stack direction="row" alignItems="center" spacing={4}>
        <Rating
          readOnly
          precision={0.5}
          value={rating}
          size={size === "medium" ? "medium" : "small"}
        />

        {isDefined(reviewsCount) && (
          <Text bold variant={size === "medium" ? "body2" : "caption"}>
            {reviewsCount} {pluralize("review", reviewsCount)}
          </Text>
        )}
      </Stack>
    </Stack>
  );
}
