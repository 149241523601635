import { CalloutsWrapper } from "@clipboard-health/ui-components";
import { type SxProps, type Theme } from "@mui/material";

import { ShiftBlocksCallout } from "../Shift/Block/Callout";
import { HomeHealthCallout } from "../Shift/HomeHealth/Callout";
import { ShiftInvitesCallout } from "../Shift/Invite/Callout";
import { PriorityShiftsCallout } from "../Shift/Priority/Callout";
import { UrgentShiftsCallout } from "../Shift/Urgent/Callout";
import { AccountDeactivatedCallout } from "../Worker/AccountDeactivatedCallout";
import { AccountRestrictedCallout } from "../Worker/AccountRestrictedCallout";
import { WorkerAvailabilityCallout } from "../Worker/AvailabilityCallout";

interface ShiftDiscoveryCalloutsContainerProps {
  priorityShiftsCount: number;
  urgentShiftsCount: number;
  blockShiftsCount: number;
  homeHealthEnabled?: boolean;
  homeHealthCount?: number;
  sx?: SxProps<Theme>;
}

export function ShiftDiscoveryCalloutsContainer(props: ShiftDiscoveryCalloutsContainerProps) {
  const {
    priorityShiftsCount,
    urgentShiftsCount,
    blockShiftsCount,
    homeHealthEnabled,
    homeHealthCount,
    sx,
  } = props;
  return (
    <CalloutsWrapper sx={sx}>
      <AccountRestrictedCallout />
      <AccountDeactivatedCallout />
      <UrgentShiftsCallout count={urgentShiftsCount} />
      <ShiftInvitesCallout />
      <PriorityShiftsCallout count={priorityShiftsCount} />
      {homeHealthEnabled && <HomeHealthCallout count={homeHealthCount} />}
      <ShiftBlocksCallout count={blockShiftsCount} />
      <WorkerAvailabilityCallout />
    </CalloutsWrapper>
  );
}
