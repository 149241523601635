import { isEqual } from "lodash";
import { useForm } from "react-hook-form";

import { useShiftDiscoveryUserFiltersContext } from "../useUserFiltersContext";
import { type FiltersFormValues } from "./types";

function getDefaultFormValues(
  defaultValues: Partial<FiltersFormValues> & Pick<FiltersFormValues, "distance">
): FiltersFormValues {
  return {
    shiftTimeSlots: [],
    licenses: [],
    ...defaultValues,
  };
}

export function useFiltersForm() {
  const { setFilters, shiftTimeSlots, distance, licenses } = useShiftDiscoveryUserFiltersContext();

  const defaultFormValues = getDefaultFormValues({ distance });

  const form = useForm<FiltersFormValues>({
    defaultValues: {
      ...defaultFormValues,
      licenses,
      shiftTimeSlots,
    },
  });

  const formValues = form.watch();
  const changedFiltersCount = Object.entries(formValues).filter(
    ([key, value]) => !isEqual(value, defaultFormValues[key as keyof FiltersFormValues])
  ).length;

  const resetFilters = () => {
    form.reset(defaultFormValues);
    setFilters(defaultFormValues);
  };

  const cancelFilters = () => {
    form.reset(); // resets to values when form was initialized
  };

  const submitFilters = () => {
    setFilters(formValues);
  };

  return { form, changedFiltersCount, resetFilters, cancelFilters, submitFilters };
}
