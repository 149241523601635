import { ModalStatus, type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { useFacilityNotes } from "@src/appV2/Facilities/api/useFacilityNotes";
import { MuiThemeProvider } from "@src/appV2/lib/Theme";
import { useGetWorkplaceQuiz } from "@src/appV2/WorkplaceQuiz/api/useGetWorkplaceQuiz/useGetWorkplaceQuiz";
import { WorkplaceRulesWithQuiz } from "@src/appV2/WorkplaceQuiz/WorkplaceRulesWithQuiz/WorkplaceRulesWithQuiz";

import { useBookShift } from "../Shift/Booking/useBookShift";
import { SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH } from "../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../ShiftDiscovery/useShiftModalsDataContext";

interface LegacyQuizFlowContainerProps {
  modalState: UseModalState;
  shiftId: string;
  shiftOfferId?: string;
  workplaceId?: string;
  workplaceName?: string;
}

/**
 * This component is used to display the quiz flow in the legacy way.
 * This will be rewritten to a new designs soon, it's made like this just to unblock booking flow.
 */
export function LegacyQuizFlowContainer(props: LegacyQuizFlowContainerProps) {
  const { modalState, shiftId, shiftOfferId, workplaceId, workplaceName } = props;

  const { data: quizData } = useGetWorkplaceQuiz(workplaceId ?? "", {
    enabled: isDefined(workplaceId),
  });

  const quiz = quizData?.data?.[0];

  const { data: facilityNotes } = useFacilityNotes(workplaceId ?? "", {
    enabled: isDefined(workplaceId),
  });

  const quizModalState = useModalState(ModalStatus.OPEN);

  const { navigateToModal } = useShiftModalsDataContext();

  const { attemptBookingShift } = useBookShift({
    shiftId,
    shiftOfferId,
    workplaceId,
    navigateToModal,
    onBook: () => {
      navigateToModal(SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH, { shiftId });
    },
  });

  return (
    <MuiThemeProvider>
      {isDefined(quiz) && isDefined(facilityNotes) && isDefined(workplaceName) && (
        <WorkplaceRulesWithQuiz
          facilityName={workplaceName}
          facilityNotes={facilityNotes}
          quizRulesDialog={quizModalState}
          quiz={quiz}
          logMetadata={{
            shiftId,
            source: "booking",
          }}
          onQuizPassed={async () => {
            await attemptBookingShift();
          }}
          onCancel={() => {
            modalState.closeModal();
          }}
        />
      )}
    </MuiThemeProvider>
  );
}
