import { ToggleButton } from "@clipboard-health/ui-components";

import { TimeSlot } from "../../Shift/Open/types";
import { iconTypeByTimeSlot } from "../../Shift/TimeSlotIcon";
import { FiltersToggleButtonGroup } from "./BottomSheet/ToggleButtonGroup";
import { type FiltersFormValues } from "./BottomSheet/types";
import { FILTER_ALL_VALUE, useFilterSelectedOptions } from "./BottomSheet/useFilterSelectedOptions";

interface ShiftTimeSlotButtonGroupProps {
  value?: FiltersFormValues["shiftTimeSlots"];
  onChange: (value: FiltersFormValues["shiftTimeSlots"]) => void;
}

const timeslotOptions = [
  {
    value: TimeSlot.AM,
    label: "Day",
  },
  {
    value: TimeSlot.PM,
    label: "Evening",
  },
  {
    value: TimeSlot.NOC,
    label: "Overnight",
  },
] as const;

export function ShiftTimeSlotButtonGroup(props: ShiftTimeSlotButtonGroupProps) {
  const { value, onChange } = props;

  const { selectedOptions, onChangeSelection } = useFilterSelectedOptions(
    value ?? [],
    onChange,
    timeslotOptions.map((option) => option.value)
  );

  return (
    <FiltersToggleButtonGroup
      aria-label="Shift Times Filter Toggle Buttons"
      value={selectedOptions}
      onChange={(newValue) => {
        onChangeSelection(newValue ?? []);
      }}
    >
      <ToggleButton value={FILTER_ALL_VALUE}>All</ToggleButton>
      {timeslotOptions.map(({ value, label }) => (
        <ToggleButton
          key={value}
          value={value}
          selected={selectedOptions.includes(value) || selectedOptions.includes(FILTER_ALL_VALUE)}
          iconType={iconTypeByTimeSlot[value]}
        >
          {label}
        </ToggleButton>
      ))}
    </FiltersToggleButtonGroup>
  );
}
