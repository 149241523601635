import { Stack } from "@mui/material";

import { DialogFooter } from "../../../components/DialogFooter";
import { IconButton } from "../../../components/IconButton";
import { LoadingButton } from "../../../components/LoadingButton";
import { TextButton } from "../../../components/TextButton";

interface FiltersBottomSheetFooterProps {
  showClearFormButton: boolean;
  openShiftsCount: number;
  openShiftsIsLoading: boolean;
  onClear: () => void;
  onSubmit: () => void;
  onCancel: () => void;
}

export function FiltersBottomSheetFooter(props: FiltersBottomSheetFooterProps) {
  const { showClearFormButton, openShiftsCount, openShiftsIsLoading, onClear, onCancel, onSubmit } =
    props;

  const openShiftsIsEmpty = openShiftsCount === 0 && !openShiftsIsLoading;

  const label = openShiftsIsEmpty ? "No shifts available" : `Show ${openShiftsCount} shifts`;

  return (
    <DialogFooter
      withShadow
      orientation="vertical"
      // Margin top is to avoid having the bottom sheet jump around
      // when the clear button visibility changes. It's 48px because
      // that's the height of the clear button (24px) plus the gap (24px).
      sx={{ gap: 7, marginTop: showClearFormButton ? 0 : 10 }}
    >
      {showClearFormButton && (
        <TextButton size="medium" aria-label="Clear all" onClick={onClear}>
          Clear all
        </TextButton>
      )}

      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <IconButton size="large" variant="outlined" iconType="close" onClick={onCancel} />

        <LoadingButton
          aria-label={openShiftsIsLoading ? "Loading Open Shifts For Given Filters" : label}
          isLoading={openShiftsIsLoading}
          variant="contained"
          size="large"
          onClick={onSubmit}
        >
          {label}
        </LoadingButton>
      </Stack>
    </DialogFooter>
  );
}
