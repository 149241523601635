import { type OpenShiftDatesResponseWithUrgent } from "../../Shift/Calendar/types";
import { TimeSlot } from "../../Shift/Open/types";
import type { UserFilters } from "./types";

/**
 * This function filters the open shift count data based on the shift time slots filter (so far, more filters might be added)
 * NOTE: It doesn't filter `slots` as it's not used in the UI anywhere
 */
export function filterOpenShiftsCount(
  countData: OpenShiftDatesResponseWithUrgent,
  filters: Pick<UserFilters, "shiftTimeSlots">
): OpenShiftDatesResponseWithUrgent {
  const { shiftTimeSlots } = filters;

  const dates = Object.keys(countData);

  if (dates.length === 0 || shiftTimeSlots.length === 0) {
    return countData;
  }

  return dates.reduce<OpenShiftDatesResponseWithUrgent>((result, date) => {
    const {
      am: unfilteredAmCount = 0,
      pm: unfilteredPmCount = 0,
      noc: unfilteredNocCount = 0,
      ...restData
    } = countData[date];

    const am = shiftTimeSlots.includes(TimeSlot.AM) ? unfilteredAmCount : 0;
    const pm = shiftTimeSlots.includes(TimeSlot.PM) ? unfilteredPmCount : 0;
    const noc = shiftTimeSlots.includes(TimeSlot.NOC) ? unfilteredNocCount : 0;

    const total = am + pm + noc;

    // skip the date if no shifts are available
    if (total === 0) {
      return result;
    }

    return {
      ...result,
      [date]: {
        ...restData,
        am,
        pm,
        noc,
        total,
      },
    };
  }, {});
}
