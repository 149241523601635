export const FILTER_ALL_VALUE = "filter_all";

type FilterAllValue = typeof FILTER_ALL_VALUE;
type SelectableOptions<Option> = Array<Option | FilterAllValue>;

/**
 * A hook that manages the selected options for a filter.
 * Allows selecting all options or a subset of options.
 */
export function useFilterSelectedOptions<Option>(
  selected: Option[],
  onChange: (selected: Option[]) => void,
  allOptions: Option[]
) {
  const selectedOptions: SelectableOptions<Option> =
    selected.length > 0 ? selected : [FILTER_ALL_VALUE];

  const onChangeSelection = (newSelection: SelectableOptions<Option>) => {
    // If "All" is selected, deselect it and select only the clicked option
    if (selectedOptions.includes(FILTER_ALL_VALUE)) {
      const clickedOption = newSelection.find((option) => option !== FILTER_ALL_VALUE);
      if (clickedOption) {
        onChange([clickedOption as Option]);
        return;
      }
    }

    // All options selected separately
    if (allOptions.every((option) => newSelection.includes(option))) {
      onChange([]);
      return;
    }

    // "All" button not included - just return filtered selection
    if (!newSelection.includes(FILTER_ALL_VALUE)) {
      onChange(newSelection as Option[]);
      return;
    }

    // "All" option was clicked to select all
    onChange([]);
  };

  return { selectedOptions, onChangeSelection };
}
