import { isDefined } from "@clipboard-health/util-ts";
import { SpecialQualifications } from "@src/appV2/Qualifications/types";
import { formatISO, parseISO } from "date-fns";

import type { OpenShift } from "../../Shift/Open/types";
import type { UserFilters } from "./types";

interface FilterOpenShiftsProps {
  shifts: OpenShift[];
}

function shouldFilterOut<T>(filter: T[] | undefined, value: T) {
  return isDefined(filter) && filter.length > 0 && !filter.includes(value);
}

/**
 * NURSE shifts are shown to workers with either an LVN or RN
 * This is a workaround to make filters work properly
 * https://www.notion.so/TD-Remove-NURSE-Shifts-e3f10a38050741fd9d9beff5d4468fd3
 * CAREGIVER shifts are shown to workers with the CNA qualification.
 */
function getLicenseFilterValues(licenses: string[] | undefined) {
  if (!isDefined(licenses)) {
    return undefined;
  }

  const extraQualifications: string[] = [];

  if (
    [SpecialQualifications.RN, SpecialQualifications.LVN].some((license) =>
      licenses.includes(license)
    )
  ) {
    extraQualifications.push(SpecialQualifications.NURSE);
  }

  if (licenses.includes(SpecialQualifications.CNA)) {
    extraQualifications.push(SpecialQualifications.CAREGIVER);
  }

  return [...licenses, ...extraQualifications];
}

export function filterOpenShifts(
  props: FilterOpenShiftsProps,
  filters: Partial<Omit<UserFilters, "distance">>
) {
  const { shifts } = props;
  const { dates, shiftTimeSlots, licenses } = filters;

  if (shifts.length === 0) {
    return [];
  }

  return shifts.filter((shift) => {
    const { qualification, start, timeSlot } = shift.attributes;

    const shiftDate = formatISO(parseISO(start), { representation: "date" });

    if (shouldFilterOut(dates, shiftDate)) {
      return false;
    }

    if (shouldFilterOut(shiftTimeSlots, timeSlot)) {
      return false;
    }

    return !shouldFilterOut(getLicenseFilterValues(licenses), qualification);
  });
}
