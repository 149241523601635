import { getTheme } from "@clipboard-health/ui-theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { PageWrapper } from "../components/PageWrapper";
import { Navbar } from "../Navbar";
import { ShiftDiscoveryUserFiltersProvider } from "./Filters/useUserFiltersContext";
import { ShiftDiscoveryRouter } from "./Router";

export function ShiftDiscoveryContainer() {
  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <ShiftDiscoveryUserFiltersProvider>
        <PageWrapper>
          <ShiftDiscoveryRouter />
          <Navbar />
        </PageWrapper>
      </ShiftDiscoveryUserFiltersProvider>
    </ThemeProvider>
  );
}
